import { mapGetters, mapState } from "vuex";

export default {
  install(Vue) {
    Vue.mixin({
      computed: {
        ...mapState({
          currentUser: (state) => state.Users.currentUser,
          themeConfig: (state) => state.Client.theme,
        }),
        ...mapGetters({
          beamFeatures: "Settings/beamFeatures",
        }),
        _isAdminArea() {
          return this.$route.path.indexOf("/admin") === 0;
        },
        _isAdminUser() {
          // There are multiple admin roles, they all have to be checked...

          return this._isSuperAdminUser
              || this.$userCan(['appearance.create','appearance.delete']) 
              || this.$userCan(['assets.create','assets.delete'])
              || this.$userCan(['authpages.create','authpages.publish'])
              || this.$userCan(['data_tables.create','data_tables.delete'])
              || this.$userCan(['dxg.create','dxg.delete'])
              || this.$userCan(['forms.create','forms.publish'])
              || this.$userCan(['helpdesk.delete','helpdesk.update'])
              || this.$userCan(['homepages.create','homepages.publish'])
              || this.$userCan(['libraries.create','libraries.delete'])
              || this.$userCan(['pages.create','pages.publish'])
              || this.$userCan(['reporting.download','reporting.view'])
              || this.$userCan(['showcases.create','showcases.manage'])
              || this.$userCan(['users.approve','users.create']);
        },
        _isLoggedIn() {
          return !!this.$store.state.JWT.token;
        },
        _isSuperAdminUser() {
          return this.currentUser?.email === 'root@monigle.com' 
              && this.$userCan(['features.update','notifications.create']);
        },
        _userReady() {
          return this.currentUser && this.currentUser.permissions.abilities;
        },
        _themeName() {
          return this.themeConfig?.theme ?? "legacy";
        },
        _isLegacyTheme() {
          return "legacy" === this._themeName;
        },
        _authPagesIsEnabled() {
          return (
            !this._isLegacyTheme && this.beamFeatures.authPages !== "disabled"
          );
        },
        _authPagesIsPublished() {
          return (
            !this._isLegacyTheme && this.beamFeatures.authPages === "published"
          );
        },
        _dataTablesEnabled() {
          return this.beamFeatures.dataTables === "published";
        },
        _internalAccessRequestsEnabled() {
          return this.beamFeatures.internalAccessRequests === "published";
        },
        _dxgIsEnabled() {
          return this.beamFeatures.dxg !== "disabled";
        },
        _dxgIsPublished() {
          return this.beamFeatures.dxg === "published";
        },
        _entitiesIsEnabled() {
          return (
            !this._isLegacyTheme && this.beamFeatures.entities !== "disabled"
          );
        },
        _entitiesIsPublished() {
          return (
            !this._isLegacyTheme && this.beamFeatures.entities === "published"
          );
        },
        _fileExchangeIsEnabled() {
          return this.beamFeatures.fileExchange !== "disabled";
        },
        _fileExchangeIsPublished() {
          return this.beamFeatures.fileExchange === "published";
        },
        _formEditorEnabled() {
          return this.beamFeatures.formEditor !== "disabled";
        },
        _helpdeskIsEnabled() {
          return this.beamFeatures.helpdesk !== "disabled";
        },
        _helpdeskIsPublished() {
          return this.beamFeatures.helpdesk === "published";
        },
        _localesIsEnabled() {
          return (
            !this._isLegacyTheme &&
            this.beamFeatures.localization !== "disabled"
          );
        },
        _localesIsPublished() {
          return (
            !this._isLegacyTheme &&
            this.beamFeatures.localization === "published"
          );
        },
        _pptGeneratorIsEnabled() {
          return this.beamFeatures.pptGenerator !== "disabled";
        },
        _pptGeneratorIsPublished() {
          return this.beamFeatures.pptGenerator === "published";
        },
        _publicIsEnabled() {
          return this.beamFeatures.public !== "disabled";
        },
        _publicIsPublished() {
          return this.beamFeatures.public === "published";
        },
        _showcaseIsEnabled() {
          return this.beamFeatures.showcase !== "disabled";
        },
        _showcaseIsPublished() {
          return this.beamFeatures.showcase === "published";
        },
        _notificationsAreEnabled() {
          return this.beamFeatures.notifications !== "disabled";
        },
        _notificationsArePublished() {
          return this.beamFeatures.notifications === "published";
        },
        _dataTablesArePublished() {
          return this.beamFeatures.dataTables === "published";
        },
        _assetPermissionsArePublished() {
          return this.beamFeatures.assetPermissions === "published";
        },
      },
      methods: {
        /**
         * spread operator allows 'ability' to be single string, comma-separated strings, or an array of strings
         * ex: $userCan('pages.create') or $userCan('pages.create', 'pages.delete') or $userCan(['pages.create', 'pages.delete'])
         *
         * @param {string|Array} ability - dot-notated user ability(ies)
         * @returns {boolean} true if ALL abilities are true, false if ANY abilities are false or do not exist
         *
         */
        $userCan(...abilities) {
          let result = false;

          if (abilities && this._userReady) {
            const arr =
              typeof abilities[0] === "string" ? abilities : abilities[0];
            const userAbilities = this.currentUser.permissions.abilities;

            for (let i = 0; i < arr.length; i++) {
              const ability = arr[i].split(".");
              // There must be an area and action for each ability
              if (ability.length === 2) {
                const area = ability[0];
                const action = ability[1];
                // If ANY area and/or action are false or missing, return false immediately
                if (
                  userAbilities[area] &&
                  userAbilities[area][action] === true
                ) {
                  result = true;
                } else {
                  result = false;
                  break;
                }
              } else {
                result = false;
                break;
              }
            }
          }

          return result;
        }
      },
    });
  },
};
